import React from "react"
import styles from "./expandableMenu.module.css"
import Hamburger from "./hamburger"
import Menu from "./menu"

export default function ExpandableMenu(props) {
  return (
    <div>
      <Hamburger active={props.menuActive} onClick={props.handleToggle} />
      <Menu active={props.menuActive} />
      <div
        className={props.menuActive ? styles.contentBlur : ``}
        onClick={props.handleToggle}
      />
    </div>
  )
}
