import React from "react"
import Logo from "../../images/logo.svg"
import LogoText from "../../images/logo-text.svg"
import styles from "./header.module.css"
import { navigate } from "gatsby"

export default function Header(props) {
  return (
    <header>
      <div className={styles.header}>
        <div>
          {props.showTextLogo ? (
            <div onClick={() => { navigate("/") }}>
              <LogoText className={styles.logoText} />
            </div>
          ) : (
            <Logo className={styles.logo} />
          )}
        </div>
      </div>
    </header>
  )
}
