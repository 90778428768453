import { Link } from "gatsby"
import React from "react"
import styles from "./menu.module.css"

export default function Menu(props) {
  const linkState = props.active ? styles.fadeIn : styles.hidden

  return (
    <div
      className={`${styles.menuPlane} ${
        props.active ? styles.menuShown : styles.menuHidden
      }`}
    >
      <Link to="/" className={linkState}>
        Home
      </Link>
      <Link to="/coaching-prozess/" className={linkState}>
        Coaching-Prozess
      </Link>
      <Link to="/faq/" className={linkState}>
        H&auml;ufige Fragen
      </Link>
      <Link to="/erfahrungsberichte/" className={linkState}>
        Erfahrungsberichte
      </Link>
      <Link to="/zeitumstellung/" className={linkState}>
        Tipps
      </Link>
      <Link to="/babysprache/" className={linkState}>
        Babysprache
      </Link>
      <Link to="/uebermich/" className={linkState}>
        &#220;ber mich
      </Link>
      <Link to="/kontakt/" className={linkState}>
        Kontakt
      </Link>
    </div>
  )
}
