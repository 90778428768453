import React from "react"

export default class ResponsiveImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0,
      height: 0,
    }

    this.portrait = props.portrait
    this.landscape = props.landscape
    this.breakpoint = props.breakpoint

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  conditionForLandscape() {
    if (this.breakpoint) {
      return this.state.width > this.breakpoint
    } else {
      return this.state.width > this.state.height
    }
  }

  render() {
    return (
      <div>{this.conditionForLandscape() ? this.landscape: this.portrait}</div>
    )
  }
}
