import React from "react"
import { Link } from "gatsby"
import styles from "./footer.module.css"

export default function Footer(props) {
  return (
    <footer className={styles.footerBar}>
      <div className={styles.footerContent}>
        <div className={`${styles.footerColumn} ${styles.left}`}>
          <Link to="/">Home</Link>
          <Link to="/coaching-prozess/">Coaching-Prozess</Link>
          <Link to="/faq/">H&auml;ufige Fragen</Link>
          <Link to="/erfahrungsberichte/">Erfahrungsberichte</Link>
          <Link to="/zeitumstellung/">Tipps</Link>
        </div>
        <div className={`${styles.footerColumn} ${styles.right}`}>
          <Link to="/babysprache/">Babysprache</Link>
          <Link to="/uebermich/">&#220;ber mich</Link>
          <Link to="/kontakt/">Kontakt</Link>
          <Link to="/impressum/">Impressum</Link>
          <Link to="/datenschutz/">Datenschutz</Link>
        </div>
      </div>
    </footer>
  )
}
