import React from "react"
import styles from "./hamburger.module.css"

export default function Hamburger(props) {
  return (
    <div
      className={`${styles.hamburger} ${
        props.active ? `${styles.active} ` : ``
      }`}
      onClick={props.onClick}
    >
      <div className={styles.bar1}></div>
      <div className={styles.bar2}></div>
      <div className={styles.bar3}></div>
    </div>
  )
}
