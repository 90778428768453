const config = {
  title: `babyschlafexpertin`,
  siteUrl: `https://www.babyschlafexpertin.de`,
  description: `Dein Baby kann eigenständig einschlafen und altersgerecht durchschlafen. Gemeinsam und auf sanfte Art finden wir deinen Weg zu ruhigen Nächten, planbaren Tagen und entspanntem Schlafen.`,
  keywords: [`baby`, `schlafcoaching`],

  author: `Micki Weinbir`,
  authorFirstName: `Micki`,
  authorAltName: `Michaela`,
  authorLastName: `Weinbir`,
  organization: `babyschlafexpertin`,
  email: `kontakt@babyschlafexpertin.de`,

  contactFormRestEndpoint: `https://y5mvabkpd3.execute-api.eu-central-1.amazonaws.com/prod/`,

  trackingConsentCookie: `babyschlafexpertin-tracking-consent`,
  googleAnalyticsID: `UA-176451759-1`,
  googleTagManagerID: `GTM-M4CMF3N`,
  facebookPixelID: `1190978887935836`,

  calendlyUrl: `https://calendly.com/babyschlafexpertin/erstgespraech`,

  colorRed: `d95d54`,
  colorGreen: `5597a3`,
  colorGray: `333333`,
  layoutBreakpointDesktop: 992,

  emailBabyLanguage: `sprache@babyschlafexpertin.de`,
  descriptionBabyLanguage: `Dein Neugeborenes kann ab der Geburt mit dir sprechen - Lerne, es zu verstehen.Für weniger Weinen, mehr Schlaf & mehr Vertrauen.`,
  organizationBabyLanguage: `Dunstan Babysprache`,

  showAdModal: false,
  adModalUrl: `https://www.kursfreunde.com/de/shop/coaching-babysprache-online-eltern-und-schwangere~p1589942`,
  // everytime you add a new adModal (i.e. new workshop with new date), replace the `adModalCookieUid` by a new random such that appearance of the modal is controlled by a new cookie
  adModalCookieUid: `d7f07ef065b9`,
}

module.exports = config
