import { navigate } from "gatsby"
import React from "react"
import ArrowDownIcon from "../../images/arrowDown.svg"
import styles from "./scrollDownAnimation.module.css"

export default class ScrollDownAnimation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      scrolledDown: true,
    }

    this.href = props.href
    this.scrollThreshold = 50

    this.updateScrollState = this.updateScrollState.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    this.updateScrollState()
    window.addEventListener("scroll", this.updateScrollState)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateScrollState)
  }

  updateScrollState() {
    this.setState({
      scrolledDown:
        document.body.scrollTop > this.scrollThreshold ||
        document.documentElement.scrollTop > this.scrollThreshold,
    })
  }

  handleClick() {
    navigate(`${this.href}`)
  }

  render() {
    return (
      <div
        className={`${styles.wrapper} ${
          this.state.scrolledDown ? styles.hidden : ``
        }`}
        onClick={this.handleClick}
      >
        <div
          className={`${styles.encircle} ${styles.bounce} ${styles.animated}`}
        >
          <div className={styles.arrow}>
            <ArrowDownIcon />
          </div>
        </div>
      </div>
    )
  }
}
